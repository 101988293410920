import { Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import clsx from 'clsx';

import { sectionBlockStyles } from './sectionBlock.styles';

/* 
  PROPS THIS COMPONENT CAN RECEIVE (all booleans):
  - onClick
  - children (the text content);
  - date (if active, h2 gets a pink color);
  - news_block (if active, p gets a gray color);
  - border_top, border_right, border_bottom;
  - arrow_right (receives a black arrow pointing right);
*/ 
export const SectionBlock = (props) => {

  const classes = sectionBlockStyles(props);

  /*
    The data from the CMS will come in the form on an object,
    if said object is empty the content from that area must not be rendered.
  */
  const renderFirstTypography = Object.keys(props.children[0].props).length >= 1;
  const renderSecondTypography = Object.keys(props.children[1].props).length >= 1;

  return (
    <div onClick={props.onClick} className={clsx(classes.section_block, props.className)}>
      {props.arrow_right ? (
        <ArrowRightIcon className={classes.section_block__arrow} />
      ) : null}

      <div className={classes.section_text_wrapper}>
        {renderFirstTypography && (
          <Typography
            variant="h3"
            component="h2"
            className={classes.section_block_title}
            dangerouslySetInnerHTML={{
              __html: `${props.children[0].props.children}`
            }}
          />
        )}

        {renderSecondTypography && (
          <Typography
            variant="h3"
            component="p"
            className={classes.section_block_sub_title}
            dangerouslySetInnerHTML={{
              __html: `${props.children[1].props.children}`
            }}
          />
        )}
      </div>
    </div>
  );
};