import { makeStyles } from '@mui/styles';

export const articleBlocksStyles = makeStyles((theme) => ({
  article_blocks: {
    width: '100%',
    height: '100%',

    [theme.breakpoints.up('md')]: {
      maxHeight: '100vh',

      borderTop: (props) =>
        props.scrollDirection
          ? null
          : `1px solid ${theme.palette.primary.light_gray}`,

      transition: 'top 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',

      position: 'sticky',
      top: (props) => (props.scrollDirection ? '0' : '3rem'),

      overflow: 'hidden scroll',
      overscrollBehavior: 'contain'
    }
  },
  _active: {
    background: theme.palette.primary.black,

    '& h2': {
      color: theme.palette.secondary.main,
    },
    '& p': {
      color: theme.palette.secondary.dark_gray
    }
  }
}));