import { makeStyles } from '@mui/styles';

export const activeArticleStyles = makeStyles((theme) => ({
  active_article: {
    width: '100%',
    height: '100%',

    padding: '1.5rem 1.5rem 5rem 1.5rem',

    background: '#F7FBFC',

    border: `1px solid ${theme.palette.primary.light_gray}`,
    borderLeft: '0',

    display: 'flex',
    flexDirection: 'column'
  },
  quote_block: {
    '& p': {
      margin: 0
    }
  },
  image_block: {
    width: '100%',
    height: 'fit-content',

    margin: '3rem 0',

    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      margin: '4rem 0',

      padding: '0 5rem'
    }
  },
  image_block__image: {
    width: '100%',
    height: '100%',

    objectFit: 'cover'
  },
  image_block_description: {
    marginTop: '1rem',

    '& p': {
      lineHeight: '140%',
      letterSpacing: '0.1em !important'
    }
  },
  body_block: {
    '& p:first-child': {
      margin: '0'
    }
  }
}));