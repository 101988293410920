import { makeStyles } from '@mui/styles'; 

export const mobileMenuStyles = makeStyles((theme) => ({
  mobile_menu_button_wrapper: {
    width: '100%',
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  mobile_menu_button: {
    width: 'fit-content',
    height: '100%',

    opacity: '0.7',

    margin: '0 auto 0 .7rem',

    background: 'none',
    border: 'none'
  },
  mobile_menu_button__text: {
    color: theme.palette.primary.main
  },
  mobile_menu_links: {
    zIndex: '5 !important',

    // Remember to override this at theme
    '& .MuiDrawer-paper': {
      marginTop: '3rem',
      padding: '1.5rem 0 0 1.5rem',
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      '& .MuiDrawer-paper': {
        width: '50%'
      }
    }
  },
  mobile_menu_link_wrapper: {
    width: 'fit-content',
    marginBottom: '.5rem',

    cursor: 'pointer',

    transition: 'opacity 0.2s ease-out',

    '&:hover': {
      opacity: '0.3'
    }
  },
  mobile_menu_link: {
    color: 'inherit',
    fontSize: 'inherit',
    letterSpacing: 'inherit',

    textDecoration: 'none',

    borderBottom: '0px solid transparent',

    transition: 'border-color .2s ease-out'
  },
  mobile_menu_link__text: {
    display: 'inline',

    color: theme.palette.primary.main
  },
  // MODIFIYERS
  _active: {
    borderBottom: '2px solid black'
  }
}));
