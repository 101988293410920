import { makeStyles } from '@mui/styles'; 

export const columnChartGraphStyles = makeStyles((theme) => ({
  column_chart_graph: {
    fontFamily: 'EB Garamond',

    background: '#F7FBFC',

    letterSpacing: '0.02em',

    position: 'relative',

    borderTop: (props) =>
      props.border_top
        ? `1px solid ${theme.palette.primary.light_gray}`
        : null,
    borderRight: (props) =>
      props.border_right
        ? `1px solid ${theme.palette.primary.light_gray}`
        : null,
    borderBottom: (props) =>
      props.border_bottom
        ? `1px solid ${theme.palette.primary.light_gray}`
        : null,

    display: 'flex',
    flexDirection: 'column',

    maxHeight: '100vh',
    height: '100vh',

    [theme.breakpoints.up('xl')]: {
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  column_chart_graph_data: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',

    margin: '1.5rem 1.5rem 0 1.5rem',

    '& span': {
      marginTop: '15px'
    },
    [theme.breakpoints.up('xl')]: {
      position: 'absolute',
      top: '1.5rem',
      left: '1.5rem',
      zIndex: '3',

      margin: '0'
    }
  },
  column_chart_graph_wrapper: {
    width: '100%',
    height: '332px',

    margin: 'auto',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      height: '500px'
    }
  },
  column_chart_graph__graph: {
    width: '90%',
    height: '100%',
    maxWidth: '46.875rem',

    [theme.breakpoints.up('sm')]: {
      width: '85%'
    },
    [theme.breakpoints.up('xxl')]: {
      maxWidth: '50rem',
    }
  }
}));
