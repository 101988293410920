import { Fade, Typography } from '@mui/material';

import { SectionHeader } from '../../components/section-header/sectionHeader';
import { StickyScrollerBox } from '../../components/sticky-scroller-box/stickyScrollerBox';
import { TextWidthLimiter } from '../../components/text-width-limiter/textWidthLimiter';

import Artwork_1 from '../../assets/images/artwork_1.jpg'; 

import { financialCalendarStyles } from './financialCalendar.styles';

/*
  OBS: The classes on the contents of 'StickyScrollerBox' have no 
  styles. They serve only for making debugging easy on devtools.
  Their actual styles are in the 'StickyScrollerBox' component.
*/
export const FinancialCalendar = () => {

  const classes = financialCalendarStyles();

  return (
    <Fade in timeout={1000}>
      <div className={classes.financial_calendar}>
        {/* Remember to re-think this 'overriden' thing */}
        <div className={classes.overridden_section_header}>
          <SectionHeader border>2022</SectionHeader>
        </div>
        <StickyScrollerBox border_bottom padding>
          <img
            src={Artwork_1}
            alt="Artwork Edition Number"
            className={classes.financial_calendar__image}
          />
          <TextWidthLimiter>
            <div className={classes.financial_calendar_text_wrapper}>
              <div className="calendar_month_wrapper">
                <Typography variant="body1" color="primary.pink">
                  Januar
                </Typography>
                <Typography variant="body1" color="primary">
                  Rückkaufangebot der Weng Fine Art AG für Aktien der ArtXX AG
                </Typography>
              </div>
              <div className="calendar_month_wrapper">
                <Typography variant="body1" color="primary.pink">
                  Februar
                </Typography>
                <Typography variant="body1" color="primary">
                  Guidance zu den Ergebnissen der ArtXX AG sowie der Weng Fine
                  Art AG für das Geschäftsjahr 2021{' '}
                </Typography>
              </div>
              <div className="calendar_month_wrapper">
                <Typography variant="body1" color="primary.pink">
                  März
                </Typography>
                <Typography variant="body1" color="primary">
                  Veröffentlichung der Bilanz der ArtXX AG zum 31.12.2021
                </Typography>
                <Typography variant="body1" color="primary">
                  Veröffentlichung der Einzelbilanz der Weng Fine Art AG zum
                  31.12.2021
                </Typography>
                <Typography variant="body1" color="primary">
                  Einladung zur Generalversammlung der ArtXX AG und
                  Bekanntmachung der Dividende für das Geschäftsjahr 2021
                </Typography>
                <Typography variant="body1" color="primary">
                  Veröffentlichung der geprüften Konzernbilanz der Weng Fine Art
                  AG zum 31.12.2021 Ordentliche Generalversammlung der ArtXX AG
                  für das Geschäftsjahr 2021 (virtuell)
                </Typography>
              </div>
              <div className="calendar_month_wrapper">
                <Typography variant="body1" color="primary.pink">
                  Mai
                </Typography>
                <Typography variant="body1" color="primary">
                  Präsentation auf der MKK in München Einladung zur
                  Hauptversammlung der Weng Fine Art AG und Bekanntmachung der
                  Dividende für das Geschäftsjahr 2021
                </Typography>
              </div>
              <div className="calendar_month_wrapper">
                <Typography variant="body1" color="primary.pink">
                  Juni
                </Typography>
                <Typography variant="body1" color="primary">
                  Juni Ordentliche Hauptversammlung der Weng Fine Art AG in
                  Düsseldorf (Präsenz-HV)
                </Typography>
              </div>
            </div>
            <Typography
              variant="h4"
              component="p"
              color="primary"
              className={classes.financial_calendar__info_date}
            >
              STAND: 31. DEZ. 2021
            </Typography>
          </TextWidthLimiter>
        </StickyScrollerBox>
      </div>
    </Fade>
  );
};
