import { makeStyles } from '@mui/styles';

export const stickyScrollerBoxStyles = makeStyles((theme) => ({
  sticky_scroller_box: {
    borderTop: (props) =>
      props.border_top
        ? `1px solid ${theme.palette.primary.light_gray}`
        : null,

    borderBottom: (props) =>
      props.border_bottom
        ? `1px solid ${theme.palette.primary.light_gray}`
        : null
  },
  sticky_scroller_box_left_column: {
    height: 'auto',

    transition: 'top 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    
    [theme.breakpoints.up('md')]: {
      maxHeight: '100vh',

      position: 'sticky',
      top: (props) => props.scrollDirection ? '0' : '3rem',
    }
  },
  sticky_scroller_box_right_column: {
    minHeight: '100vh',
    padding: (props) => props.padding ? '1.5rem' : null,

    borderLeft: `1px solid ${theme.palette.primary.light_gray}`
  },
}));
