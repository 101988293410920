import { Grid, useTheme, useMediaQuery, Typography } from '@mui/material';

import { pdfBlockStyles } from './pdfBlock.styles';

/* 
  PROPS THIS COMPONENT CAN RECEIVE (all booleans):
  - children
  - margin_top
  - border_top
  - border_right
  _ border_bottom
*/
export const PdfBlock = (props) => {

  const classes = pdfBlockStyles(props);
  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      item
      xs={12}
      md={props.full_width ? 12 : 6}
      className={classes.pdf_block}
    >
      <div className={classes.pdf_block__head}>
        <Typography variant={mobileViewport ? 'p' : 'h3'} component={!mobileViewport ? 'h3' : 'p'} color="primary">
          {props.children[0]}
        </Typography>
        <Typography variant={mobileViewport ? 'p' : 'h3'} component={!mobileViewport ? 'h3' : 'p'} color="primary">
          {props.children[1]}
        </Typography>
      </div>
      <div className={classes.pdf_icon_wrapper}>PDF</div>
    </Grid>
  );
};
