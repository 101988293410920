import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920,
      xxl: 2500
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'EB Garamond',
      letterSpacing: '0.02em',
      fontWeight: '400'
    }
  },
});

theme = createTheme(theme, {
  palette: {
    primary: {
      main: '#222',
      black: '#000',
      pink: '#FE309C',
      light_pink: '#FE309C99',
      lighter_pink: '#FE309C66',
      lightest_pink: '#FE309C33',
      light_gray: '#CCCCCC',
      green: '#5BC873'
    },
    secondary: {
      main: '#FFF',
      dark_gray: '#888888'
    },
    background: {
      main: '#F7FBFC'
    }
  },
  shape: {
    borderRadius: '0'
  },
  typography: {
    h1: {
      fontSize: '6rem',
      fontWeight: '400',
      lineHeight: 'normal',

      [theme.breakpoints.up('md')]: {
        fontSize: '7rem'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '10.625rem'
      }
    },
    h1_number: {
      fontSize: '6rem',
      fontWeight: '400',
      lineHeight: 'normal',

      [theme.breakpoints.up('md')]: {
        fontSize: '10.625rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '13.75rem'
      }
    },
    h2: {
      fontSize: '2.2rem',
      lineHeight: '115%',

      [theme.breakpoints.up('md')]: {
        fontSize: '3.3rem'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '4rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '4.5rem'
      }
    },
    h3: {
      fontSize: '1.9rem',
      lineHeight: '110%',

      [theme.breakpoints.up('md')]: {
        fontSize: '2rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '3rem'
      }
    },
    h4: {
      fontFamily: 'Archivo, sans-serif',
      letterSpacing: '0.16em',
      fontSize: '0.75rem',
      lineHeight: '1.25rem'
    },
    header_link: {
      fontSize: '1.1rem',
      fontWeight: 400,
      letterSpacing: '0.02em',
      textDecoration: 'none',

      [theme.breakpoints.up('lg')]: {
        fontSize: '1.25rem'
      }
    },
    header_homepage_link: {
      fontFamily: 'Archivo, sans-serif',
      fontSize: '.8rem',
      fontStretch: '103%',
      fontWeight: '700',
      lineHeight: '1.25rem',
      letterSpacing: '0.16em'
    },
    body1: {
      fontSize: '1.4rem',
      lineHeight: '1.75rem',

      [theme.breakpoints.up('xl')]: {
        fontSize: '1.5rem',
        lineHeight: '1.9rem'
      },
      [theme.breakpoints.up('xxl')]: {
        fontSize: '1.6rem',
        lineHeight: '2rem'
      }
    },
    body2: {
      fontFamily: 'Archivo, sans-serif',
      fontSize: '0.87rem',
      lineHeight: '1.25rem'
    },
    body3: {
      fontFamily: 'Archivo, sans-serif',
      fontSize: '0.75rem',
      letterSpacing: '0.03rem',
      lineHeight: '1rem'
    },
    quote: {
      fontStyle: 'italic',
      fontSize: '1.4rem',
      lineHeight: '1.75rem',

      [theme.breakpoints.up('xl')]: {
        fontSize: '1.5rem',
        lineHeight: '1.9rem'
      },
      [theme.breakpoints.up('xxl')]: {
        fontSize: '1.6rem',
        lineHeight: '2rem'
      }
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'p',
          quote: 'p',
          header_link: 'p',
          h1_number: 'h1'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          color: '#222',
          fontFamily: 'Archivo, sans-serif',
          fontWeight: 400,
          letterSpacing: '0.16em',
          fontStretch: '97%',
          fontSize: '13px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontFamily: 'Archivo, sans-serif !important',
          lineHeight: '1 !important',
          fontSize: '13px !important',

          padding: '11.2px 14px !important'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '13px !important',
          fontFamily: 'Archivo, sans-serif !important',
          lineHeight: '1 !important',
          fontStretch: '97% !important',

          padding: '0 5px 5px 5px',

          background: '#fff'
        },
        formControl: {
          top: '-1px !important',
          transform: 'translate(9px, 16px) scale(1)'
        },
        shrink: {
          transform: 'translate(9px, -10px) scale(0.75) !important'
        }
      }
    }
  }
});

export default theme;


