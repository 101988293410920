import { makeStyles } from '@mui/styles'; 

export const headerStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    height: '3rem',

    borderBottom: `1px solid ${theme.palette.primary.light_gray}`,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'relative',
    zIndex: '6',

    boxShadow: 'none !important',

    background: `${theme.palette.secondary.main} !important`,

    // Remember to override this at theme

    '& .MuiToolbar-regular': {
      minHeight: 'unset !important'
    },
    '& .MuiToolbar-gutters': {
      padding: 'unset !important'
    }
  },
  header_navbar: {
    height: '100%',
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  // MODIFIYERS
  _active: {
    borderBottom: '2px solid black'
  }
}));