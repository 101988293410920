import { makeStyles } from '@mui/styles';


export const contactsStyles = makeStyles((theme) => ({
  contacts_box_container: {
    height: '160vw',

    [theme.breakpoints.up('sm')]: {
      height: '110vw'
    },
    [theme.breakpoints.up('md')]: {
      height: '70vw'
    },
    [theme.breakpoints.up('lg')]: {
      height: '50vw'
    }
  },
  contacts_box: {
    maxWidth: '100%',

    [theme.breakpoints.up('lg')]: {
      maxWidth: '75%',
      marginLeft: 'auto'
    },
    [theme.breakpoints.up('xxl')]: {
      maxWidth: '65%'
    }
  },
  contacts_box_image_wrapper: {
    height: '100vw',

    [theme.breakpoints.up('sm')]: {
      height: '80vw'
    },
    [theme.breakpoints.up('md')]: {
      height: '40vw'
    },
    [theme.breakpoints.up('lg')]: {
      height: '35vw'
    }
  },
  contacts_box_image_wrapper__image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'grayscale(100%)'
  },
  contacts_info: {
    margin: '1.5rem'
  },
  contacts_info__text: {
    width: 'fit-content',

    margin: '0',

    '&:nth-child(2)': {
      color: theme.palette.primary.pink
    }
  },
  contacts_info__link: {
    width: 'fit-content',

    display: 'block',

    color: theme.palette.primary.main,
    textDecoration: 'none',

    transition: 'opacity .2s ease-in-out',

    '&:hover': {
      opacity: '0.3'
    }
  },
  // MODIFIYERS
  _align_left: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '0',
      marginRight: 'auto'
    }
  }
}));