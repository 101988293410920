import { useEffect } from 'react';

import { connect } from 'react-redux';
import {
  news__setArticles,
  news_setActiveArticle
} from '../../redux/reducers/news/news';

import { Fade, Grid, useTheme, useMediaQuery } from '@mui/material';

import { StickyScrollerBox } from '../../components/sticky-scroller-box/stickyScrollerBox';
import { SectionBlock } from '../../components/section-block/sectionBlock';
import ArticleBlocks from './article-blocks/articleBlocks';
import ActiveArticle from './active-article/activeArticle';

import { mockedArticlesData } from './mocked-articles-data/mockedArticlesData';

import { newsStyles } from './news.styles';

const News = ({ reduxActions }) => {

  const { news__setArticles, news_setActiveArticle } = reduxActions;

  const classes = newsStyles();
  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {

     if (mobileViewport) {
       news__setArticles(mockedArticlesData.slice(0, 1));
     } else {
       news__setArticles(mockedArticlesData);
     }

  }, [mobileViewport])

  useEffect(() => {

    const { id, quote, image, body } = mockedArticlesData[0];


    news_setActiveArticle({
      id,
      quote,
      image,
      body
    })

  }, [mockedArticlesData])


  return (
    <Fade in timeout={1000}>
      <div className={classes.news}>
        <Grid container>
          <StickyScrollerBox>
            <ArticleBlocks />
            <ActiveArticle />
          </StickyScrollerBox>

          {mobileViewport ? (
            <SectionBlock full_width news_block>
              <>
                {`Weng Fine Art AG un <br /> 
                  Artnet AG einigen sich <br /> 
                  auf freiwillige <br /> 
                  Sonderprüfung für <br /> 
                  Artnet`}
              </>
              <>{`Presse 8. Dezember 2022`}</>
            </SectionBlock>
          ) : null}
        </Grid>
      </div>
    </Fade>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    news__setArticles: (articlesArray) =>
      dispatch(news__setArticles(articlesArray)),
    news_setActiveArticle: (activeArticle) => dispatch(news_setActiveArticle(activeArticle))
  }
});

export default connect(null, mapDispatchToProps)(News);
