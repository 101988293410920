import { NavLink } from 'react-router-dom';

import { Typography } from '@mui/material';

import { desktopLinksStyles } from './desktopLinks.styles';


export const DesktopLinks = () => {

  const classes = desktopLinksStyles();

  return (
    <div className={classes.desktop_links}>
      <NavLink
        to="/"
        exact
        className={classes.desktop_link}
        activeClassName={classes._active}
      >
        <Typography variant="header_link" className={classes.desktop_link__text}>
          Übersicht
        </Typography>
      </NavLink>
      <NavLink
        to="/news"
        exact
        className={classes.desktop_link}
        activeClassName={classes._active}
      >
        <Typography variant="header_link" className={classes.desktop_link__text}>
          News
        </Typography>
      </NavLink>
      <NavLink
        to="/information"
        exact
        className={classes.desktop_link}
        activeClassName={classes._active}
      >
        <Typography variant="header_link" className={classes.desktop_link__text}>
          Informationen
        </Typography>
      </NavLink>
      <NavLink
        to="/statistics"
        exact
        className={classes.desktop_link}
        activeClassName={classes._active}
      >
        <Typography variant="header_link" className={classes.desktop_link__text}>
          Statistiken
        </Typography>
      </NavLink>
      <NavLink
        to="/financial-reports"
        exact
        className={classes.desktop_link}
        activeClassName={classes._active}
      >
        <Typography variant="header_link" className={classes.desktop_link__text}>
          Finanzberichte
        </Typography>
      </NavLink>
      <NavLink
        to="/financial-calendar"
        exact
        className={classes.desktop_link}
        activeClassName={classes._active}
      >
        <Typography variant="header_link" className={classes.desktop_link__text}>
          Finanzkalender
        </Typography>
      </NavLink>
      <NavLink
        to="/anual-general-meeting"
        exact
        className={classes.desktop_link}
        activeClassName={classes._active}
      >
        <Typography variant="header_link" className={classes.desktop_link__text}>
          Hauptversammlung
        </Typography>
      </NavLink>
    </div>
  );
};
