import { makeStyles } from '@mui/styles'; 

export const pieChartGraphStyles = makeStyles((theme) => ({
  pie_chart_graph: {
    fontFamily: 'EB Garamond',

    background: '#F7FBFC',

    letterSpacing: '0.02em',

    position: 'relative',

    borderTop: (props) =>
      props.border_top ? '1px solid rgba(0, 0, 0, 0.12)' : null,
    borderRight: (props) =>
      props.border_right ? '1px solid rgba(0, 0, 0, 0.12)' : null,
    borderBottom: (props) =>
      props.border_bottom ? '1px solid rgba(0, 0, 0, 0.12)' : null,

    display: 'flex',
    flexDirection: 'column',

    maxHeight: '100vh',
    height: '100vh',

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row'
    }
  },
  pie_chart_graph_data: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',

    margin: '1.5rem 0 0 1.5rem',

    [theme.breakpoints.up('sm')]: {
      margin: '0',

      position: 'absolute',
      top: '1.5rem',
      left: '1.5rem',
      zIndex: '3'
    }
  },
  pie_chart_graph_data__title: {
    margin: '0',

    fontSize: '2.2rem',
    fontWeight: '400',
    lineHeight: '100%',

    [theme.breakpoints.up('md')]: {
      fontSize: '2.7rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '3rem'
    }
  },
  pie_chart_graph_data_list: {
    listStyleType: 'none',

    padding: '0',

    margin: '5px 0 10px 0'
  },
  pie_chart_graph_data_list_item: {
    display: 'flex',
    alignItems: 'center'
  },
  pie_chart_data_list_item__bullet_point: {
    display: 'block',

    width: '.6rem',
    height: '.6rem',

    margin: '0 .5rem 0 0',

    borderRadius: '80px'
  },
  pie_chart_graph__graph: {
    width: '85%',

    margin: 'auto',

    maxWidth: '750px',

    [theme.breakpoints.up('md')]: {
      width: '45%',

      margin: '0'
    }
  },
  // MODIFYERS
  _pink_1: {
    background: theme.palette.primary.light_pink
  },
  _pink_2: {
    background: theme.palette.primary.lighter_pink
  },
  _pink_3: {
    background: theme.palette.primary.pink
  },
  _pink_4: {
    background: theme.palette.primary.lightest_pink
  }
}));
