export const mockedArticlesData = [
  {
    // Rheinische Post
    // Presse 23. Januar 2022
    id: 1,
    title: `
      <p>Kunst ist als Anlageobjekt sehr gefragt</p>
    `,
    sub_title: `
      <p>Rheinische Post 
      Presse 23. Januar 2022 </p>
    `,
    quote: `
      <p>In Zeiten von niedrigen oder sogar negativen Zinsen investieren
      viele Anleger in den Kunstmarkt. Davon profitiert auch ein
      Monheimer Unternehmen: Die Weng Fine Art AG. Deren Chef
      Rüdiger Weng prophezeit für die
      Zukunft weiteres Wachstum
    `,
    image: {
      src: 'https://res.cloudinary.com/arbid/image/upload/v1647541023/ir/w1200_h815_x599_y407_RP_mo_ruediger_weng-66ec53747cb251f1-2_1_agwwl8.png',
      description: `
        <p>RÜDIGER WENG, CHEF DER FINE ART AG IN  
        MONHEIM, IST EXPERTE RUND UM DIE  
        ANLAGE IN KUNSTOBJEKTE. 
        FOTO: MATZERATH, RALPH (RM-)</p>
      `
    },
    body: `
      <p>Der DAX insgesamt kletterte in 2021 um knapp 16 Prozent. Die
      Aktionäre der Weng Fine Art AG, des Unternehmens von Rüdiger K.
      Weng, freuten sich sogar über ein Plus von mehr als 200 Prozent. Der
      seit 2016 an der Rheinpromenade ansässige Kunsthandel ging 2012 an
      die Börse, und seit September 2020 werden die Papiere auf XETRA
      gehandelt. </p>
      <p>Die Weng-Fine-Art-Anteile waren im Vorjahr unter den Top 10 der am
      besten performenden Aktien an der deutschen Börse. Mit einem
      Marktwert von etwa 200 Millionen Euro gehört sie aber immer noch zu
      den „Small Caps“. Das sind Aktien, die aufgrund ihres relativ
      geringeren Marktgewichts im Verhältnis zu einer Daimler oder Siemens
      zu den Börsen-Nebenwerten gehören. Die gesamtwirtschaftliche
      Entwicklung begünstigte Wengs Geschäftsidee, die schon vor Corona
      auf Online-Handel (E-Commerce) setzte. Weng verkauft auf
      Großhandelsebene, aber auch an private Kunden rund um den Globus
      Werke zeitgenössischer, weltbekannter Künstler. </p>
      <p>„In Zeiten niedriger Zinsen und reichlich zur Verfügung stehenden
      Geldern suchen viele Kunst auch als Anlageobjekt“, erläuterte er im
      RP-Interview die für ihn erfreuliche Situation. Das Jahresende 2021
      hatte es noch einmal in sich. Die Kunstwerke mit Preisen zwischen
      5000 und 100.000 Euro sind nur bedingt als Weihnachtsgeschenk
      geeignet, aber trotzdem wollten viele Kunstkäufer noch etwas
      erwerben, „und sei es nur, um Liquidität zu parken“. Die letzten
      Monate im Jahr 2021 bescherten der AG das umsatz- und ertragreichste
      Quartal, „wir konnten gleichzeitig </p>
      <p>Umsätze und operative Margen verbessern“, so Weng. Der gebürtige
      Krefelder räumt ein, in gewisser Weise zu den Corona-Gewinnern zu
      gehören, dank seines Geschäftsmodells sowie der Lagerhaltung. „Der
      Kunstmarkt ist seit Jahren ein Verkäufermarkt. Es gewinnt derjenige,
      der Ware hat. Während Kommissionäre infolge der
      Kontaktbeschränkungen lange auf dem Trockenen saßen, konnten wir die
      Kundenwünsche durchgängig bedienen und dabei auch erhebliche
      Wertgewinne realisieren – ein großer Teil der Erhöhung unserer
      Handelsmarge kommt aus dem Wertzuwachs des Altbestands. Wir haben
      offensichtlich im Corona-Jahr auch die richtigen Entscheidungen
      getroffen.“ </p>
      <p>Weng und seine Mitarbeiter ruhen sich nicht auf dem Erfolg aus.
      Parallel zum „klassischen“ Geschäft startete die AG in 2021 ein
      komplett neues Geschäftsfeld, den Bereich „Art/Fin/Tech“. Die
      Tokenisierung und Fraktionalisierung von Kunstwerken auf Basis der
      Blockchain-Technologie soll zusammen mit der Etablierung einer
      elektronischen Handelsplattform den Kunstmarkt revolutionieren. </p>
      <p>„Der Kunstmarkt steht am Beginn einer revolutionären Metamorphose:
      Von einem extrem heterogenen, illiquiden, intransparenten und
      exklusiven Nischenmarkt zu einer breit zugänglichen, homogenen und
      digitalen Asset-Klasse“, prophezeit Weng. Ziel ist es, analoge
      Kunstwerke digital verbrieft und auf Plattformen handelbar zu
      machen. Durch sogenannte NFTs (Non-Fungible Token) sowie FTs öffnet
      sich der Markt für komplett neue Teilnehmer und Käufergruppen. </p>
      <p>Festzustellen ist nach Ablauf des vergangenen Jahres, dass es bei
      der neuen Anlegergeneration – wie bei Kryptowährungen – neben dem
      emotionalen Aspekt von Kunst auch um spielerische Elemente geht.
      Weng ist sicher, dass bei zunehmender Marktreife immer mehr
      professionelle Anleger hinzukommen. „Der klassische Kunstsammler
      wird nicht verschwinden, im Vergleich zu Finanzanlegern aber in
      Zukunft nur noch einen Bruchteil ausmachen.“ Das Wachstumspotenzial
      ist riesig. Aktuell beträgt das Volumen des gesamten Kunstmarkts
      nicht mehr als einem Zwanzigstel der Marktkapitalisierung von Apple. </p>
      <p>Nach Wengs Ansicht werden sowohl Preise als auch Volumen deutlich
      wachsen, Stichwort: mangelnde Verfügbarkeit, „sehr viel Geld trifft
      auf wesentlich weniger Ware“. Zum anderen wird der Effekt
      hinzukommen, dass ein Kunst-Token per se teurer als das Werk selbst
      ist. Dies liegt an der höheren Liquidität. Eine börsennotierte AG
      erhält auch eine höhere Bewertung als eine GmbH (bei vergleichbaren
      Geschäftsergebnissen). </p>
    `
  },
  {
    id: 2,
    title: `
      <p>
        ArtXX AG erhöht Grundkapital zur 
        Bedienung des Optionsprogramms – 
        Spielraum für Expansion wird durch 
        weiter aufgestockte Kreditlinien 
        ausgeweitet
      </p>
    `,
    sub_title: '<p>Presse 27. Oktober 202</p>',
    quote: null,
    image: null,
    body: `
      <ul>
        <li>Umsatz stieg um etwa 36 %</li>
        <li>Gewinnmarge aus Eigenware erhöhte sich von 76 % auf etwa 109 %</li>
        <li>Handelsgewinn legte um etwa 60 % zu</li>
        <li>Hoher Cashflow führt zur weiteren Steigerung der Eigenkapitalquote auf fast 70 %</li>
        <li>Deutliche Dividendenerhöhung der ArtXX AG möglich</li>
      </ul>
      <p>
        Die ArtXX AG, die Schweizer E-Commerce-Tochter der Weng Fine Art AG, 
        hat nach den vorläufigen Zahlen im Geschäftsjahr 2021 mit etwa 7.700 
        TEUR ihren Umsatz deutlich um etwa 36 % gegenüber dem Vorjahr (5.668 TEUR) 
        gesteigert. Damit hat sich das Wachstumstempo beschleunigt – dies 
        unbeeinflusst von negativen äußeren Einflüssen wie Pandemie, stockenden 
        Lieferketten sowie Zins- und Inflationsdiskussionen. Dabei hat das 
        Geschäftsvolumen im 2. Halbjahr um etwa 70 % gegenüber den ersten sechs 
        Monaten des Jahres 2021 zugenommen.  
      </p>
      <p>
        Zusätzlich zum Umsatz hat sich auch die Marge (berechnet als Aufschlagsatz) 
        für die Eigenware außergewöhnlich stark von 76 % auf ca. 109 % erhöht. Diese 
        Entwicklung ist den Preissteigerungen über die gesamte Breite des 
        Kunst-Editionsmarktes zu verdanken, die vor allem zu einer Höherbewertung 
        des Altbestandes der ArtXX AG durch den Kunstmarkt geführt hat. Verkäufe 
        mit Gewinnen von mehreren hundert Prozent sind im vergangenen Jahr keine 
        Seltenheit gewesen.  
      </p>
      <p>  
        Beide positiven Entwicklungen haben zu einer weiteren kräftigen Steigerung 
        des Handelsgewinns auf etwa 3.700 TEUR geführt, der sich damit um ca. 60 % 
        gegenüber dem bisherigen Höchstwert aus dem Jahr 2020 (2.316 TEUR) 
        verbessert hat. Auch hier hat sich die positive Entwicklung im Laufe des 
        Jahres weiter beschleunigt. Dem Sonderertrag im Jahr 2020 aus der 
        Kompensationsleistung eines Geschäftspartners in Höhe von 450 TEUR stand 
        im vergangenen Jahr allerdings kein vergleichbarer Ertrag entgegen.  
      </p>
      <p>
        Die operativen Kosten haben sich unterproportional zum stark gestiegenen 
        Handelsgewinn erhöht. Die Finanzierungskosten lagen auch 2021 auf einem 
        sehr niedrigen Niveau.  
      </p>
      <p>
        Der Gewinn des Geschäftsjahres 2021 wird den bisherigen Höchststand 
        aus dem Jahr 2020 (1.809 TEUR) deutlich übertreffen, da die Rentabilität 
        des Unternehmens auch in 2021 sehr hoch lag. Dabei ist zu berücksichtigen,
        dass die ersten nennenswerten Erträge aus dem Tokenisierungsgeschäft erst
         im Geschäftsjahr 2022 erwartet werden.  
      </p>
      <p>
        Der positive Cashflow sowohl im Finanzierungs- als auch im operativen Bereich 
        hat dazu geführt, dass sich die Nettoverschuldung des Unternehmens im 
        vergangenen Jahr stark verringert hat.  
      </p>
      <p>
        Aufgrund der hohen Erträge, die zu einer Verbesserung der Eigenkapitalquote 
        der ArtXX AG zum 31. Dezember 2021 auf fast 70 % geführt haben, wäre eine 
        kräftige Erhöhung der Ausschüttung für das Geschäftsjahr 2021 an die 
        Aktionäre möglich, von der insbesondere die Konzernmutter Weng Fine Art 
        AG profitieren würde. Zu der Dividendenhöhe wird der Verwaltungsrat 
        voraussichtlich im März 2022 -mit der Vorlage der Bilanz zum 31. Dezember 
        2021- einen Vorschlag verkünden. Zu diesem Zeitpunkt will das Management 
        auch über die zur weiteren Beschleunigung des Wachstums eingeleiteten 
        neuen Strategiemaßnahmen informieren.  
      </p>
      <p>
        Die vorläufigen Zahlen für das Geschäftsjahr 2021 aus dem Handelsgeschäft 
        der Weng Fine Art AG sollen am 11. Februar 2022 bekanntgegeben werden.  
      </p>
    `
  },
  {
    id: 3,
    title: `
      <p>
        Aktiensplit erfolgt am 15. Oktober 
        2021 | Kein weiterer Verkauf aus dem 
        Treasury Stock mehr in 2021
      </p>
    `,
    sub_title: '<p>Presse 12. Oktober 2021</p>',
    quote: null,
    image: null,
    body: '<p>Article number 3</p>'
  },
  {
    id: 4,
    title: `
      <p>
        Investment in the Data business 
        unit: Weng Fine Art accquires a stake 
        in technology company ArtFacts
      </p>
    `,
    sub_title: '<p>Presse 6. Januar 2022</p>',
    quote: null,
    image: null,
    body: '<p>Article number 4</p>'
  }
];
