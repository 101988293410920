
import { makeStyles } from '@mui/styles'; 

export const sectionHeaderStyles = makeStyles((theme) => ({
  section_header_wrapper: {
    width: '100%',

    textAlign: 'center',

    padding: '8rem 0 4rem',

    borderBottom: (props) =>
      props.border ? `1px solid ${theme.palette.primary.light_gray}` : 'unset',

    [theme.breakpoints.up('md')]: {
      padding: '9rem 0 3rem'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '12rem 0 5.5rem'
    }
  },
}));
