import { Pie } from '@ant-design/plots';

import { Typography } from '@mui/material';

import clsx from 'clsx';

import { pieChartGraphStyles } from './pieChartGraph.styles';

export const PieChartGraph = ({ border_top, border_right, border_bottom }) => {

  const classes = pieChartGraphStyles({ border_top, border_right, border_bottom });

  const data = [
    {
      type: 'Rüdiger K. Weng ',
      value: 29.22
    },
    {
      type: 'Rüdiger K. Weng A+A GmbH',
      value: 29.22
    },
    {
      type: 'WFA AG (Treasury Stock)',
      value: 36.36
    },
    {
      type: 'Streubesitz',
      value: 32.6
    }
  ];

  const chartConfigs = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#FE309C99', '#FE309C66', '#FE309C', '#FE309C33'],
    radius: 1,
    innerRadius: 0.87,
    statistic: false,
    legend: false,
    label: false,
    pieStyle: {
      lineWidth: 0,
      cursor: 'pointer'
    },
    interactions: [
      {
        type: 'element-active'
      }
    ],
    state: {
      active: {
        animate: { duration: 200, easing: 'easeInOut' },
        style: {
          lineWidth: 1,
          stroke: 'black',
        }
      }
    }
  };

  return (
    <div className={clsx(classes.pie_chart_graph, classes._border_bottom)}>
      <div className={classes.pie_chart_graph_data}>
        <Typography variant="h3" component="h2" color="primary">
          Aktionärsstruktur
        </Typography>
        <ul className={classes.pie_chart_graph_data_list}>
          <li className={classes.pie_chart_graph_data_list_item}>
            <span
              className={clsx(
                classes.pie_chart_data_list_item__bullet_point,
                classes._pink_1
              )}
            />
            <Typography variant="body1" color="primary">
              Rüdiger K. Weng 29,22 %
            </Typography>
          </li>
          <li className={classes.pie_chart_graph_data_list_item}>
            <span
              className={clsx(
                classes.pie_chart_data_list_item__bullet_point,
                classes._pink_2
              )}
            />
            <Typography variant="body1" color="primary">
              Rüdiger K. Weng A+A GmbH 29,22 %
            </Typography>
          </li>
          <li className={classes.pie_chart_graph_data_list_item}>
            <span
              className={clsx(
                classes.pie_chart_data_list_item__bullet_point,
                classes._pink_3
              )}
            />
            <Typography variant="body1" color="primary">
              WFA AG (Treasury Stock) 36,36 %
            </Typography>
          </li>
          <li className={classes.pie_chart_graph_data_list_item}>
            <span
              className={clsx(
                classes.pie_chart_data_list_item__bullet_point,
                classes._pink_4
              )}
            />
            <Typography variant="body1" color="primary">
              Streubesitz 32,60 %
            </Typography>
          </li>
        </ul>
        <Typography variant="h4" component="p" color="primary">
          STAND: 31. DEZ. 2021
        </Typography>
      </div>
      <div className={classes.pie_chart_graph__graph}>
        <Pie {...chartConfigs} />
      </div>
    </div>
  );
};
