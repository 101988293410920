import { useScrollTrigger, useTheme, useMediaQuery, Slide, Fade, AppBar, Toolbar, CssBaseline } from '@mui/material';

import MobileMenu from './mobile-menu/MobileMenu';
import { DesktopLinks } from './desktop-links/DesktopLinks';
import { HomepageLink } from './homepage-link/HomepageLink';

import { headerStyles } from './header.styles';

export const Header = () => {

  const scrollDirection = useScrollTrigger();
  
  const classes = headerStyles();
  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Fade in timeout={1000}>
      <div className="header_wrapper">
        <Slide in={!scrollDirection}>
          <AppBar position="fixed" className={classes.header}>
            <CssBaseline />
            <Toolbar className={classes.header_navbar}>
              {mobileViewport ? <MobileMenu /> : <DesktopLinks />}
            </Toolbar>
          </AppBar>
        </Slide>
        <HomepageLink slideIn={!scrollDirection} />
      </div>
    </Fade>
  );
};