import { makeStyles } from '@mui/styles'; 

export const pdfRowStyles = makeStyles((theme) => ({
  pdf_row: {
    border: `1px solid ${theme.palette.primary.light_gray}`,

    marginBottom: '1rem !important',

    padding: '1.5rem',

    position: 'relative',

    cursor: 'pointer',

    display: 'flex',

    transition: 'background-color .2s ease-in-out',

    '&:hover': {
      backgroundColor: '#F7FBFC',

      '& div:last-child': {
        color: theme.palette.primary.pink,
        borderColor: theme.palette.primary.pink
      }
    }
  },
  pdf_row_text_wrapper: {
    width: 'fit-content',
    height: 'fit-content',

    marginBottom: '1.5rem',

    '& p': {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': '1',
      '-webkit-box-orient': 'vertical'
    }
  },
  pdf_row__icon: {
    width: '3rem',
    height: '4rem',

    padding: '.5rem',

    border: '2px solid black',

    transition: 'color .2s ease-in-out, border-color .2s ease-in-out',

    margin: 'auto 0 auto auto',

    fontSize: '.8rem',
    textAlign: 'center',
    fontFamily: 'Archivo, sans-serif'
  }
}));
