import { Fade, useTheme, useMediaQuery, Typography } from '@mui/material';

import { StickyScrollerBox } from '../../components/sticky-scroller-box/stickyScrollerBox';
import { TextWidthLimiter } from '../../components/text-width-limiter/textWidthLimiter';
import { PieChartGraph } from '../../components/pie-chart-graph/pieChartGraph';
import { PdfBlock } from '../../components/pdf-block/pdfBlock';

import Artwork_2 from '../../assets/images/artwork_2.jpg';

import { informationStyles } from './information.styles';

/*
  OBS: The classes that are not from 'informationStyles' 
  on the contents of 'StickyScrollerBox' have no styles. 
  They serve only for making debugging easy on devtools.
  Their actual styles are in 'informationStyles' and on
  the 'StickyScrollerBox' component.
*/
export const Information = () => {

  const classes = informationStyles();
  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Fade in timeout={1000}>
      <div className={classes.information}>
        <StickyScrollerBox border_top border_bottom padding>
          <img
            src={Artwork_2}
            alt="Artwork Edition Number"
            className={classes.information__image}
          />
          <TextWidthLimiter>
            <div className={classes.information_text_content}>
              <div className="information_header">
                <Typography variant="h3" component="h2" color="primary">
                  Unternehmensinfo
                </Typography>
              </div>
              <div className="information_box">
                <Typography variant="body1" color="primary">
                  Weng Fine Art AG Rheinpromenade 8 40789 Monheim am Rhein
                  Deutschland
                </Typography>
              </div>
              <div className="information_box">
                <Typography variant="body1" color="primary.pink">
                  Relefon
                </Typography>
                <Typography variant="body1" color="primary">
                  +49 (0)2173 690870-0
                </Typography>
              </div>
              <div className="information_box">
                <Typography variant="body1" color="primary.pink">
                  Gründungsdatum
                </Typography>
                <Typography variant="body1" color="primary">
                  16.07.1999
                </Typography>
              </div>
              <div className="information_box">
                <Typography variant="body1" color="primary.pink">
                  Geschäftsjahr
                </Typography>
                <Typography variant="body1" color="primary">
                  Kalenderjahr
                </Typography>
              </div>
              <div className="information_box">
                <Typography variant="body1" color="primary.pink">
                  Rechnungslegung
                </Typography>
                <Typography variant="body1" color="primary">
                  HGB
                </Typography>
              </div>
              <div className="information_box">
                <Typography variant="body1" color="primary.pink">
                  Vorstand
                </Typography>
                <Typography variant="body1" color="primary">
                  Rüdiger K. Weng, Düsseldorf
                </Typography>
              </div>
              <div className="information_box">
                <Typography variant="body1" color="primary.pink">
                  Aufsichtsrat
                </Typography>
                <Typography variant="body1" color="primary">
                  Christian W. Röhl, Berlin (Vorsitzender)
                </Typography>
                <Typography variant="body1" color="primary">
                  Dietrich von Frank, Zürich/Schweiz (stellvertretender
                  Vorsitzender)
                </Typography>
                <Typography variant="body1" color="primary">
                  Patrick Kiss, Hamburg (Mitglied)
                </Typography>
                <Typography variant="body1" color="primary">
                  Florian Illies, Berlin (Mitglied)
                </Typography>
                <Typography variant="body1" color="primary">
                  Wim Zwitserloot, Milsbeek/Niederlande (Ersatzmitglied)
                </Typography>
              </div>
            </div>
          </TextWidthLimiter>
        </StickyScrollerBox>
        <StickyScrollerBox border_bottom padding>
          <PieChartGraph border_bottom={mobileViewport} />
          <TextWidthLimiter>
            <div className="information_company_summary">
              <Typography variant="h3" color="primary" className={classes.information_company_summary__title}>
                Die Artx XX AG
              </Typography>
              <Typography variant="body1" color="primary">
                Die Weng Fine Art AG (WFA) ist eines der führenden und
                profitabelsten Kunsthandels-unternehmen in Europa. Das
                Unternehmen mit Sitz in Monheim am Rhein wurde 1994 von Rüdiger
                K. Weng gegründet und ist seit 2012 die einzige an der Börse
                gelistete Kunsthandelsgesellschaft in Europa. Mit derzeit vier
                Geschäftsbereichen und einem Team aus Finanz-, Kunst- und
                Digital-experten betreut das Unternehmen Kunden auf der ganzen
                Welt. Im Fokus des Unternehmens steht dabei der Handel von
                Werken international renommierter Künstler des 20. und 21.
                Jahr-hunderts wie Andy Warhol, Pablo Picasso, Jeff Koons,
                Gerhard Richter, Damien Hirst, Alex Katz, Robert Longo und Ai
                Weiwei. Die Weng Fine Art konzentriert sich auf das
                Business-to-Business-Geschäft und beliefert die großen
                internationalen Auktionshäuser sowie namhafte Händler und
                Galerien. Mit ihrer Schweizer Tochtergesellschaft ArtXX AG
                betreibt die WFA unter der Marke “Weng Contemporary” ein
                E-Commerce-Geschäft für Editionen der wichtigsten
                zeitgenössischen Künstler. Die Weng Fine Art ist zudem
                maßgeblich an der Artnet AG beteiligt, dem weltweit führenden
                Daten- und Informationsanbieter für den Kunstmarkt, die 2020 ein
                assoziiertes Unternehmen der WFA geworden ist. Zusammen mit
                Partnern aus der Finanz- und Technologieindustrie beteiligt sich
                die WFA nun auch an der Entwicklung des digitalen Kunstmarkts
                auf Basis der Blockchain-Technologie, um den Zugang für
                Investoren zum Kunstmarkt zu erleichtern und Transparenz für
                Kunst als An-lageklasse zu schaffen.
                <Typography
                  variant="h4"
                  component="span"
                  color="primary"
                  className={classes.information__info_date}
                >
                  STAND: 31. DEZ. 2021
                </Typography>
              </Typography>
            </div>
          </TextWidthLimiter>
        </StickyScrollerBox>
        <PdfBlock full_width>
          <>Satzung der Art XX AG</>
          <></>
        </PdfBlock>
      </div>
    </Fade>
  );
};
