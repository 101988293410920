const INITIAL_STATE = {
  news: {
    articles: [],
    activeArticle: {
      id: 1,
      quote: null,
      image: {
        src: null,
        description: null
      },
      body: ''
    },
  }
}

export const news = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'NEWS__SET_ARTICLES': 
      return {
        ...currentState,
        news: {
          ...currentState.news,
          articles: action.payload
        }
      }
    case 'NEWS__SET_ACTIVE_ARTICLE': 
      return {
        ...currentState,
        news: {
          ...currentState.news,
          activeArticle: action.payload
        }
      };
    default: 
      return currentState
  }
}

export const news__setArticles = (articlesArray) => ({
  type: 'NEWS__SET_ARTICLES',
  payload: articlesArray
});

export const news_setActiveArticle = (articleData) => ({
  type: 'NEWS__SET_ACTIVE_ARTICLE',
  payload: articleData
});