import { NavLink } from 'react-router-dom';

import { Typography } from '@mui/material';

import { homepageLinkStyles } from './homepageLink.styles';

export const HomepageLink = ({ slideIn }) => {

  const classes = homepageLinkStyles({ slideIn });

  return (
    <NavLink to="/" exact className={classes.homepage_link}>
      <Typography
        variant="header_homepage_link"
        component="p"
      >
        ARTXX
      </Typography>
    </NavLink>
  );
};
