import { textWidthLimiterStyles } from './textWidthLimiter.styles';

export const TextWidthLimiter = ({ children }) => {

  const classes = textWidthLimiterStyles();

  return (
    <div className={classes.text_width_limiter}>
      {children}
    </div>
  )
}
