// MATERIAL UI
import { useTheme, useMediaQuery, Fade, Grid } from '@mui/material';
// COMPONENTS
import { SectionHeader } from '../../components/section-header/sectionHeader';
import { PdfBlock } from '../../components/pdf-block/pdfBlock';
import { PdfRow } from '../../components/pdf-row/pdfRow';
// STYLES
import { financialReportsStyles } from './financialReports.styles';

/*
  OBS: The classes on the contents of 'StickyScrollerBox' have no 
  styles. They serve only for making debugging easy on devtools.
  Their actual styles are in the 'StickyScrollerBox' component.
*/

export const FinancialReports = () => {
  // STYLE HOOKS
  const classes = financialReportsStyles();
  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Fade in timeout={1000}>
      <div className={classes.financial_reports}>
        <Grid container>
          <PdfBlock border_top border_right border_bottom>
            <>Jahresabschluss Art XX AG 2021</>
            <>31. Dez. 2021</>
          </PdfBlock>
          <PdfBlock border_top={!mobileViewport} border_bottom>
            <>Bilanz der ArtXX AG</>
            <>31. Dez. 2021</>
          </PdfBlock>
        </Grid>
        <Grid container className={classes.financial_reports_row}>
          <SectionHeader>Publiziert in 2021</SectionHeader>
          <PdfRow>
            <>Jahresabschluss Art XX AG</>
            <>zum 30. Dez. 2020</>
          </PdfRow>
          <PdfRow>
            <>Bilanz Art XX AG zum</>
            <>zum 30. Dez. 2020</>
          </PdfRow>
        </Grid>
        <Grid container className={classes.financial_reports_row}>
          <SectionHeader>2020</SectionHeader>
          <PdfRow>
            <>Jahresabschluss Art XX AG</>
            <>zum 30. Dez. 2019</>
          </PdfRow>
          <PdfRow>
            <>Bilanz Art XX AG zum</>
            <>zum 30. Dez. 2019</>
          </PdfRow>
        </Grid>
        <Grid container className={classes.financial_reports_row}>
          <SectionHeader>2019</SectionHeader>
          <PdfRow>
            <>Jahresabschluss Art XX AG</>
            <>zum 30. Dez. 2018</>
          </PdfRow>
          <PdfRow>
            <>Bilanz Art XX AG zum</>
            <>zum 30. Dez. 2018</>
          </PdfRow>
        </Grid>
      </div>
    </Fade>
  );
};
