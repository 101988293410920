import { connect } from 'react-redux';

import { Typography } from '@mui/material';

import { TextWidthLimiter } from '../../../components/text-width-limiter/textWidthLimiter';

import { activeArticleStyles } from './activeArticle.styles';

const ActiveArticle = ({ reduxProps: { activeArticle } }) => {

  const { quote, image, body } = activeArticle;

  const classes = activeArticleStyles();

  return (
    <div className={classes.active_article}>
      {quote && (
        <TextWidthLimiter>
          <Typography
            variant="quote"
            component="div"
            dangerouslySetInnerHTML={{ __html: `${quote}` }}
            className={classes.quote_block}
          />
        </TextWidthLimiter>
      )}

      {image && (
        <div className={classes.image_block}>
          <img
            alt={image.description}
            src={image.src}
            className={classes.image_block__image}
          />
          <Typography
            variant="h4"
            component="div"
            className={classes.image_block_description}
            dangerouslySetInnerHTML={{ __html: `${image.description}` }}
          />
        </div>
      )}

      {body && (
        <TextWidthLimiter>
          <Typography
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{ __html: `${body}` }}
            className={classes.body_block}
          />
        </TextWidthLimiter>
      )}
    </div>
  );
}

const mapStateToProps = (currentState) => ({
  reduxProps: {
    activeArticle: currentState.newsReducer.news.activeArticle
  }
});

export default connect(mapStateToProps)(ActiveArticle);