import { Typography, useTheme, useMediaQuery } from '@mui/material';

import { footerStyles } from './footer.styles';


export const Footer = () => {

  const classes = footerStyles();
  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <footer className={classes.footer}>
      {!mobileViewport ? (
        <div className={classes.footer_first_half}>
          <>
            <div className={classes.footer_info_column}>
              <Typography variant="h4" color="primary">
                BUY ART
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/artworks"
                  target="_blank"
                  rel="noreferrer"
                >
                  Browse Artworks
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/artists"
                  target="_blank"
                  rel="noreferrer"
                >
                  Browse Artists
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/gifting"
                  target="_blank"
                  rel="noreferrer"
                >
                  Gifting Ideas
                </a>
              </Typography>
            </div>
            <div className={classes.footer_info_column}>
              <Typography variant="h4" color="primary">
                CONTACT US
              </Typography>
              <Typography variant="body2" color="primary">
                <a href="/" className={classes.footer_info_column__link}>
                  WhatsApp
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a href="/" className={classes.footer_info_column__link}>
                  WeChat
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="mailto:info@wengcontemporary.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Email
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.instagram.com/weng_contemporary/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.facebook.com/Weng.Contemporary/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.artsy.net/partner/weng-contemporary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Artsy
                </a>
              </Typography>
            </div>
            <div className={classes.footer_info_column}>
              <Typography variant="h4" color="primary">
                SERVICES
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/services"
                  target="_blank"
                  rel="noreferrer"
                >
                  Art Advisory
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/services"
                  target="_blank"
                  rel="noreferrer"
                >
                  Viewing Appointment
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/services"
                  target="_blank"
                  rel="noreferrer"
                >
                  Partnerships
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/services"
                  target="_blank"
                  rel="noreferrer"
                >
                  Interior Decoration
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/services"
                  target="_blank"
                  rel="noreferrer"
                >
                  Real State
                </a>
              </Typography>
            </div>
            <div className={classes.footer_info_column}>
              <Typography variant="h4" color="primary">
                ABOUT US
              </Typography>
              <Typography variant="body2" color="primary">
                <a href="/" className={classes.footer_info_column__link}>
                  Investor Relation
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/latest"
                  target="_blank"
                  rel="noreferrer"
                >
                  Latest
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/faqs"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQs
                </a>
              </Typography>
              <Typography variant="body2" color="primary">
                <a
                  className={classes.footer_info_column__link}
                  href="https://www.wengcontemporary.com/contacts"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contacts
                </a>
              </Typography>
            </div>
          </>
        </div>
      ) : null}
      <div className={classes.footer_second_half}>
        <Typography variant="body3" color="secondary">
          © 2022 WENG CONTEMPORARY. All rights reserved.
        </Typography>
        <div className={classes.footer_info_row}>
          <Typography variant="body3" color="secondary">
            <a
              href="https://www.google.com/maps/place/Weng+Contemporary+(ArtXX+AG)/@47.1658992,8.5165998,15z/data=!4m2!3m1!1s0x0:0xf9ccd95acaebb39?sa=X&ved=2ahUKEwjLpY6Ov6n1AhXZZs0KHSoAAksQ_BJ6BAg0EAU"
              target="_blank"
              rel="noreferrer"
            >
              Sankt-Oswalds-Gasse 1, 6300, Zug, Switzerland
            </a>
          </Typography>
        </div>
        <div className={classes.footer_info_row}>
          <Typography variant="body3" color="secondary">
            <a href="mailto:info@wengcontemporary.com">
              info@wengcontemporary.com
            </a>
          </Typography>
          <span className={classes.copyDot}>•</span>
          <Typography variant="body3" color="secondary">
            <a href="tel:+41(0)432293128">T +41(0)43 229 31 28</a>
          </Typography>
        </div>
        <div className={classes.footer_info_row}>
          <Typography variant="body3" color="secondary">
            <a
              href="https://www.wengcontemporary.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </Typography>
          <span className={classes.copyDot}>•</span>
          <Typography variant="body3" color="secondary">
            <a
              href="https://www.wengcontemporary.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms & conditions
            </a>
          </Typography>
        </div>
      </div>
    </footer>
  );
};