import { makeStyles } from '@mui/styles'; 

export const financialReportsStyles = makeStyles((theme) => ({
  financial_reports: {
    marginTop: '9rem',
    paddingBottom: '45vh',

    borderBottom: `1px solid ${theme.palette.primary.light_gray}`
  },
  // MODIFIYERS
  financial_reports_row: {
    padding: '0 2rem 0',

    [theme.breakpoints.up('sm')]: {
      padding: '0 5rem 0'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 15rem 0'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 25% 0'
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 30% 0'
    },
    [theme.breakpoints.up('xxl')]: {
      padding: '0 35% 0'
    }
  }
}));
