import { Grid, Typography } from '@mui/material';

import clsx from 'clsx';

import { SectionHeader } from '../../../components/section-header/sectionHeader';

import Giorgia from '../../../assets/images/giorgia.jpg';
import Melanie from '../../../assets/images/melanie.jpg';

import { contactsStyles } from './contacts.styles';

export const Contacts = () => {

  const classes = contactsStyles();

  return (
    <div className="contacts">
      <SectionHeader>Haben Sie Fragen an uns?</SectionHeader>
      <Grid container>
        <Grid className={classes.contacts_box_container} item xs={12} md={6}>
          <div className={classes.contacts_box}>
            <div className={classes.contacts_box_image_wrapper}>
              <img
                className={classes.contacts_box_image_wrapper__image}
                src={Giorgia}
                alt="Giorgia Zardetto"
              />
            </div>
            <div className={classes.contacts_info}>
              <Typography
                variant="body1"
                className={classes.contacts_info__text}
              >
                Managing Director
              </Typography>
              <Typography
                variant="body1"
                className={classes.contacts_info__text}
              >
                Giorgia Zardetto
              </Typography>
              <Typography
                variant="body1"
                component="a"
                href="mailto: giorgia@wengcontemporary.com"
                className={classes.contacts_info__link}
              >
                giorgia@wengcontemporary.com
              </Typography>
              <Typography
                variant="body1"
                component="a"
                href="tel: +41 76 541 56 58"
                className={classes.contacts_info__link}
              >
                +41 76 541 56 58
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={classes.contacts_box_container} item xs={12} md={6}>
          <div className={clsx(classes.contacts_box, classes._align_left)}>
            <div className={classes.contacts_box_image_wrapper}>
              <img
                className={classes.contacts_box_image_wrapper__image}
                src={Melanie}
                alt="Melanie Moske"
              />
            </div>
            <div className={classes.contacts_info}>
              <Typography
                variant="body1"
                className={classes.contacts_info__text}
              >
                Chief Digital Officier
              </Typography>
              <Typography
                variant="body1"
                className={classes.contacts_info__text}
              >
                Melanie Moske
              </Typography>
              <Typography
                variant="body1"
                component="a"
                href="mailto: moske@wengfineart.com"
                className={classes.contacts_info__link}
              >
                moske@wengfineart.com
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
