// REACT ROUTER DOM
import { Switch, Route } from 'react-router-dom';
// MUI
import { ThemeProvider } from '@mui/material/styles';
import theme from './material-ui/theme';
// PAGES
import { FactsAndFigures } from './pages/facts-and-figures/factsAndfigures';
import News from './pages/news/news';
import { FinancialCalendar } from './pages/financial-calendar/financialCalendar';
import { FinancialReports } from './pages/financial-reports/financialReports';
import { Information } from './pages/information/information';
import { AnualGeneralMeeting } from './pages/anual-general-meeting/anualGeneralMeeting';
import { Statistics } from './pages/statistics/statistics';
// COMPONENTS
import { Header } from './components/header/header';
import { Footer } from './components/footer/footer';
// import { LanguagePrompt } from './components/language-prompt/languagePrompt';
// STYLES
import { appStyles } from './App.styles';


export const App = () => {

  // STYLE HOOKS
  const classes = appStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.app}>
        <Header />
        <Switch>
          <Route exact path="/" component={FactsAndFigures} />
          <Route path="/news" component={News} />
          <Route path="/financial-calendar" component={FinancialCalendar} />
          <Route path="/financial-reports" component={FinancialReports} />
          <Route path="/information" component={Information} />
          <Route path="/anual-general-meeting" component={AnualGeneralMeeting} />
          <Route path="/statistics" component={Statistics} />
        </Switch>
        <Footer />
      </div>
    </ThemeProvider>
  );
};
