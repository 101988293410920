import { Column } from '@ant-design/plots';

import { useTheme, useMediaQuery, Typography } from '@mui/material';

import { columnChartGraphStyles } from './columnChartGraph.styles';

/* 
  PROPS THIS COMPONENT CAN RECEIVE (all booleans):
  - data
  - children
  - border_top, border_right, border_bottom
*/

export const ColumnChartGraph = ({ data, children, ...restOfProps }) => {

  const theme = useTheme();
  const classes = columnChartGraphStyles({ ...restOfProps });
  const smartPhoneViewport = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  const chartConfigs = {
    data,
    xField: 'year',
    yField: 'euros',
    label: false,
    autoFit: true,
    yAxis: {
      tickCount: 11,
      position: 'right',
      grid: {
        line: {
          style: {
            stroke: '#CCCCCC80',
            lineWidth: 2,
            cursor: 'pointer'
          }
        }
      },
      label: {
        offset: 10,
        style: {
          fill: '#000',
          fontFamily: 'Archivo, sans-serif'
        },
        formatter: (data) => {
          return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
      }
    },
    xAxis: {
      tickLine: false,
      tickCount: smartPhoneViewport ? 9 : 18,
      label: {
        autoHide: true,
        offset: 15,
        style: {
          fill: '#000',
          fontFamily: 'Archivo, sans-serif'
        }
      }
    },
    columnStyle: {
      fill: '#F7FBFC99',
      lineWidth: 2,
      stroke: '#FE309C',
      cursor: 'pointer'
    },
    meta: {
      year: {
        alias: 'Year'
      },
      euros: {
        alias: 'Euros'
      }
    },
    interactions: [
      {
        type: mobileViewport ? 'element-single-selected' : 'element-active'
      }
    ],
    state: {
      selected: {
        animate: { duration: 200, easing: 'easeInOut' },
        style: {
          lineWidth: 0,
          fill: '#FE309C99'
        }
      },
      active: {
        animate: { duration: 200, easing: 'easeInOut' },
        style: {
          lineWidth: 0,
          fill: '#FE309C99'
        }
      }
    }
  };

  return (
    <div className={classes.column_chart_graph}>
      <div className={classes.column_chart_graph_data}>
        <Typography variant="h3" component="h2" color="primary">
          {children[0]}
        </Typography>
        {children[1] ? (
          <Typography variant="body1" color="primary">
            {children[1]}
          </Typography>
        ) : null}
        <Typography variant="h4" component="p" color="primary">
          STAND: 31. DEZ. 2021
        </Typography>
      </div>
      <div className={classes.column_chart_graph_wrapper}>
        <div className={classes.column_chart_graph__graph}>
          <Column {...chartConfigs} />
        </div>
      </div>
    </div>
  );
};
