import { makeStyles } from '@mui/styles';

export const desktopLinksStyles = makeStyles((theme) => ({
  desktop_links: {
    height: '100%',
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  desktop_link: {
    width: '100%',
    height: '100%',

    borderBottom: '0px solid transparent',

    borderRight: `1px solid ${theme.palette.primary.light_gray}`,

    transition: 'border-color 1s ease-out',

    textDecoration: 'none',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',

    transition: 'opacity .2s ease-in-out',

    '&:hover': {
      opacity: '0.3'
    }
  },
  desktop_link__text: {
    color: theme.palette.primary.black
  },
  // MODIFIYERS
  _active: {
    borderBottom: '2px solid black'
  }
}));