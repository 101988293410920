import { connect } from 'react-redux';
import { mobile__openNavbar } from '../../../redux/reducers/app/app';

import { NavLink } from 'react-router-dom';

import { Drawer, Typography } from '@mui/material';

import { mobileMenuStyles } from './mobileMenu.styles';

const MobileMenu = ({ reduxProps, reduxActions }) => {

  const { open_navbar_prop } = reduxProps;
  const { mobile__openNavbar } = reduxActions;

  const classes = mobileMenuStyles();

  return (
    <>
      <div className={classes.mobile_menu_button_wrapper}>
        <button
          onClick={() => mobile__openNavbar(!open_navbar_prop)}
          className={classes.mobile_menu_button}
        >
          <Typography variant="h4" component="h1" className={classes.mobile_menu_button__text}>
            MENU
          </Typography>
        </button>
      </div>
      <Drawer
        open={open_navbar_prop}
        onClose={() => mobile__openNavbar(false)}
        className={classes.mobile_menu_links}
      >
        <div
          onClick={() => mobile__openNavbar(false)}
          className={classes.mobile_menu_link_wrapper}
        >
          <NavLink
            to="/"
            exact
            className={classes.mobile_menu_link}
            activeClassName={classes._active}
          >
            <Typography
              variant="h3"
              component="p"
              className={classes.mobile_menu_link__text}
            >
              Übersicht
            </Typography>
          </NavLink>
        </div>
        <div
          onClick={() => mobile__openNavbar(false)}
          className={classes.mobile_menu_link_wrapper}
        >
          <NavLink
            to="/news"
            exact
            className={classes.mobile_menu_link}
            activeClassName={classes._active}
          >
            <Typography
              variant="h3"
              component="p"
              className={classes.mobile_menu_link__text}
            >
              News
            </Typography>
          </NavLink>
        </div>
        <div
          onClick={() => mobile__openNavbar(false)}
          className={classes.mobile_menu_link_wrapper}
        >
          <NavLink
            to="/information"
            exact
            className={classes.mobile_menu_link}
            activeClassName={classes._active}
          >
            <Typography
              variant="h3"
              component="p"
              className={classes.mobile_menu_link__text}
            >
              Informationen
            </Typography>
          </NavLink>
        </div>
        <div
          onClick={() => mobile__openNavbar(false)}
          className={classes.mobile_menu_link_wrapper}
        >
          <NavLink
            to="/statistics"
            exact
            className={classes.mobile_menu_link}
            activeClassName={classes._active}
          >
            <Typography
              variant="h3"
              component="p"
              className={classes.mobile_menu_link__text}
            >
              Statistiken
            </Typography>
          </NavLink>
        </div>
        <div
          onClick={() => mobile__openNavbar(false)}
          className={classes.mobile_menu_link_wrapper}
        >
          <NavLink
            to="/financial-reports"
            exact
            className={classes.mobile_menu_link}
            activeClassName={classes._active}
          >
            <Typography
              variant="h3"
              component="p"
              className={classes.mobile_menu_link__text}
            >
              Finanzberichte
            </Typography>
          </NavLink>
        </div>
        <div
          onClick={() => mobile__openNavbar(false)}
          className={classes.mobile_menu_link_wrapper}
        >
          <NavLink
            to="/financial-calendar"
            exact
            className={classes.mobile_menu_link}
            activeClassName={classes._active}
          >
            <Typography
              variant="h3"
              component="p"
              className={classes.mobile_menu_link__text}
            >
              Finanzkalender
            </Typography>
          </NavLink>
        </div>
        <div
          onClick={() => mobile__openNavbar(false)}
          className={classes.mobile_menu_link_wrapper}
        >
          <NavLink
            to="/anual-general-meeting"
            exact
            className={classes.mobile_menu_link}
            activeClassName={classes._active}
          >
            <Typography
              variant="h3"
              component="p"
              className={classes.mobile_menu_link__text}
            >
              Hauptversammlung
            </Typography>
          </NavLink>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (currentState) => ({
  reduxProps: {
    open_navbar_prop: currentState.appReducer.app.mobile.open_navbar
  }
})

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    mobile__openNavbar: (boolean) => dispatch(mobile__openNavbar(boolean))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);