const INITIAL_STATE = {
  app: {
    mobile: {
      open_navbar: false,
    },
    desktop: {}
  }
}

export const app = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MOBILE__OPEN_NAVBAR':
      return {
        ...currentState,
        app: {
          ...currentState.app,
          mobile: {
            ...currentState.app.mobile,
            open_navbar: action.payload,
          }
        }
      }
    default: 
      return currentState
  }
}

export const mobile__openNavbar = (boolean) => ({
  type: 'MOBILE__OPEN_NAVBAR',
  payload: boolean
});