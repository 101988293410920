import { makeStyles } from '@mui/styles'; 

export const factsAndFiguresStyles = makeStyles((theme) => ({
  facts_and_figures: {
    borderBottom: `1px solid ${theme.palette.primary.light_gray}`
  },
  stock_data: {
    height: '100vw',
    position: 'relative',

    borderRight: `1px solid ${theme.palette.primary.light_gray}`,
    borderBottom: `1px solid ${theme.palette.primary.light_gray}`,

    background: theme.palette.background.main,

    [theme.breakpoints.up('sm')]: {
      height: '50vw',
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
    }
  },
  stock_data_info: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',

    position: 'absolute',
    top: '1.5rem',
    left: '1.5rem',

    '& p': {
      marginBottom: '10px'
    }
  },
  stock_data_price: {
    width: '100%',
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  // If this typography appears again, add to theme
  stock_data_price__variation: {
    marginTop: '-15px !important',

    color: theme.palette.primary.green,
    fontFamily: 'Archivo, sans-serif !important'
  }
}));
