import { makeStyles } from '@mui/styles'; 

export const pdfBlockStyles = makeStyles((theme) => ({
  pdf_block: {
    height: '100vw',

    cursor: 'pointer',

    transition: 'background-color .2s ease-in-out',

    position: 'relative',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginTop: (props) => (props.margin_top ? '10rem !important' : null),

    borderTop: (props) =>
      props.border_top ? `1px solid ${theme.palette.primary.light_gray}` : null,

    borderRight: (props) =>
      props.border_right
        ? `1px solid ${theme.palette.primary.light_gray}`
        : null,

    borderBottom: (props) =>
      props.border_bottom
        ? `1px solid ${theme.palette.primary.light_gray}`
        : null,

    '&:hover': {
      backgroundColor: '#F7FBFC',

      '& div': {
        color: theme.palette.primary.pink,
        borderColor: theme.palette.primary.pink
      }
    },
    [theme.breakpoints.up('sm')]: {
      height: '50vw'
    },
    [theme.breakpoints.up('md')]: {
      height: '25rem'
    },
    [theme.breakpoints.up('xl')]: {
      height: '28.125rem'
    }
  },
  pdf_block__head: {
    display: 'flex',
    flexDirection: 'column',

    margin: '0',

    position: 'absolute',
    top: '1.5rem',
    left: '1.5rem'
  },
  pdf_icon_wrapper: {
    width: '5rem',
    height: '7rem',

    fontSize: '1rem',
    fontFamily: 'Archivo, sans-serif',

    padding: '.7rem 1rem 1rem 1rem',

    border: '2px solid black',
    textAlign: 'center',

    transition: 'color .2s ease-in-out, border-color .2s ease-in-out',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',

      width: '7rem',
      height: '9rem'
    }
  }
}));
