import { makeStyles } from '@mui/styles'; 

export const footerStyles = makeStyles((theme) => ({
  footer: {
    width: '100%'
  },
  footer_first_half: {
    fontFamily: 'Archivo, sans-serif',

    width: '100%',
    padding: '1rem 0',

    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      padding: '1.5rem 0 1.5rem'
    }
  },
  footer_info_column: {
    minWidth: '130px',
    margin: '0 1.4rem',

    '& h3': {
      margin: '0 0 .6rem 0'
    }
  },
  footer_info_column__title: {
    margin: '0 0 .6rem 0',

    color: theme.palette.primary.main
  },
  footer_info_column__text: {
    width: '100%',
    display: 'block',

    margin: '0 0 .9rem 0',

    color: theme.palette.primary.main,
    lineHeight: '20px',
    letterSpacing: '0.02em'
  },
  footer_info_column__link: {
    display: 'block',

    margin: '0',

    color: 'inherit',
    fontSize: 'inherit',
    textDecoration: 'none',

    transition: 'opacity .2s ease-in-out',

    cursor: 'pointer',

    '&:hover': {
      opacity: '0.3'
    }
  },
  footer_form: {
    width: '100%',

    marginTop: '.9rem !important'
  },
  footer_info_column__button: {
    width: '100%',

    padding: '6px 16px',

    marginTop: '0.75rem',

    background: theme.palette.primary.main,

    border: 'none',

    color: theme.palette.secondary.main,
    fontFamily: 'Archivo, sans-serif',
    fontSize: '0.75rem',
    textAlign: 'center',
    lineHeight: '1.75',
    letterSpacing: '.16em',

    transition: 'color .2s ease-in-out',

    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.primary.pink
    }
  },
  footer_second_half: {
    background: theme.palette.primary.black,

    padding: '1rem 1rem 2rem',

    '& a': {
      color: 'inherit',
      fontSize: 'inherit',
      letterSpacing: 'inherit',
      lineHeight: 'inherit',
      textDecoration: 'none',
      transition: 'opacity 0.2s ease-out',

      '&:hover': {
        opacity: 0.3
      }
    },
    [theme.breakpoints.up('sm')]: {
      padding: '2rem'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '10.3rem'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '15rem'
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: '24.7rem'
    },
  },
  footer_info_row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  copyDot: {
    padding: '0 0.5rem',
    color: theme.palette.secondary.main,
    fontSize: '0.75rem',
    lineHeight: '16px'
  }
}));
