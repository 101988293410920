import { makeStyles } from '@mui/styles'; 

export const informationStyles = makeStyles((theme) => ({
  information: {
    borderBottom: `1px solid ${theme.palette.primary.light_gray}`,
    marginTop: '9rem'
  },
  information__image: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  information_text_content: {
    width: '100%',

    '& div': {
      marginTop: '1rem',
      marginBottom: 'unset',

      '&:first-child': {
        marginTop: 'unset'
      },
      '&:last-child': {
        marginBottom: '4rem'
      },
      [theme.breakpoints.up('md')]: {
        width: 'unset',
        maxWidth: '35rem'
      }
    },
    '& span': {
      display: 'block',

      marginTop: '2rem',
      marginBottom: '4rem'
    }
  },
  information_company_summary__title: {
    marginBottom: '1rem !important'
  },
  information__info_date: {
    display: 'block',
    margin: '2rem 0 5rem 0 !important'
  }
}));
