import { Grid, Typography } from '@mui/material';

import { pdfRowStyles } from './pdfRow.styles';


export const PdfRow = ({ children }) => {

  const classes = pdfRowStyles();

  return (
    <Grid item xs={12} className={classes.pdf_row}>
      <div className={classes.pdf_row_text_wrapper}>
        <Typography variant="body1" color="primary">{children[0]}</Typography>
        {children[1] ? (
          <Typography variant="body1" color="primary">
            {children[1]}
          </Typography>
        ) : null}
      </div>
      <div className={classes.pdf_row__icon}>PDF</div>
    </Grid>
  );
};
