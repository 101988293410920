import { makeStyles } from '@mui/styles'; 

export const financialCalendarStyles = makeStyles((theme) => ({
  financial_calendar: {
    '& h3': {
      marginTop: '1.5rem'
    }
  },
  financial_calendar__image: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  financial_calendar_text_wrapper: {
    width: '100%',

    '& div': {
      marginTop: '1rem',
      marginBottom: 'unset',

      '&:first-child': {
        marginTop: 'unset'
      },
      [theme.breakpoints.up('md')]: {
        width: 'unset',
        maxWidth: '35rem'
      }
    },
  },
  overridden_section_header: {
    '& h1': {
      fontSize: '6rem'
    },

    [theme.breakpoints.up('sm')]: {
      '& h1': {
        fontSize: '10.625rem'
      }
    }
  },
  financial_calendar__info_date: {
    margin: '2rem 0 4rem 0 !important'
  }
}));
