import { makeStyles } from '@mui/styles';

export const homepageLinkStyles = makeStyles((theme) => ({
  homepage_link: {
    width: '100%',
    height: '3rem',

    color: theme.palette.primary.black,
    textDecoration: 'none',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'fixed',
    top: (props) => (props.slideIn ? '3rem' : '0'),
    zIndex: '4',

    transition:
      'top 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, color .2s ease-in-out',

    '&:hover': {
      color: theme.palette.primary.pink
    }
  },
}));