import { Fade, Grid, Typography } from '@mui/material';

import { SectionHeader } from '../../components/section-header/sectionHeader';
import { SectionBlock } from '../../components/section-block/sectionBlock.js';
import { Contacts } from './contacts/contacts';

import { factsAndFiguresStyles } from './factsAndFigures.styles';


export const FactsAndFigures = () => {

  const classes = factsAndFiguresStyles();

  return (
    <Fade in timeout={1000}>
      <div className={classes.facts_and_figures}>
        <SectionHeader border>Art XX AG Investor Relations</SectionHeader>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className={classes.stock_data}>
              <div className={classes.stock_data_info}>
                <Typography variant="h3" component="h2" color="primary">
                  Aktienkurs
                </Typography>
                <Typography variant="body1" color="primary">
                  (Euro)
                </Typography>
                <Typography variant="h4" component="p" color="primary">
                  STAND: 31. DEZ. 2021s
                </Typography>
              </div>
              <div className={classes.stock_data_price}>
                <Typography variant="h1_number">516.80</Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.stock_data_price__variation}
                >
                  +1.80 (+0.35%)
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12}>
              <SectionBlock border_right border_bottom>
                <>
                  {`<p>Herzlich Willkommen im Investor  
                  Relations Bereich der ArtXX AG.  
                  Hier finden Sie schnell und unkompliziert  
                  unsere Finanzberichte sowie interessante  
                  Analysen, Statistiken und wertvolle  
                  Informationen, die insbesondere für 
                  Finanzinvestoren von Bedeutung sind.</p>`}
                </>
                <></>
              </SectionBlock>
            </Grid>
            <Grid item xs={12}>
              <SectionBlock date border_right border_bottom>
                <>{`<p>9. März 2021</p>`}</>
                <>
                  {`<p>Veröffentlichung der Bilanz der ArtXX AG für
                  das Geschäftsjahr 2020</p>`}
                </>
              </SectionBlock>
            </Grid>
          </Grid>
        </Grid>

        <Contacts />
      </div>
    </Fade>
  );
};