import { Typography } from '@mui/material';

import { sectionHeaderStyles } from './sectionHeader.styles';


export const SectionHeader = ({ children, border }) => {

  const classes = sectionHeaderStyles({ border });

  return (
    <div className={classes.section_header_wrapper}>
      <Typography variant="h2" component="h1">
        {children}
      </Typography>
    </div>
  );
};
