import { useTheme, useMediaQuery, Fade, Grid } from '@mui/material';

import { PieChartGraph } from '../../components/pie-chart-graph/pieChartGraph';
import { ColumnChartGraph } from '../../components/column-chart-graph/columnChartGraph';

import { statisticsStyles } from './statistics.styles';


export const Statistics = () => {

  const classes = statisticsStyles();
  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  const data_1 = [
    {
      year: '2004',
      euros: 2200
    },
    {
      year: '2005',
      euros: 2400
    },
    {
      year: '2006',
      euros: 2800
    },
    {
      year: '2007',
      euros: 3000
    },
    {
      year: '2008',
      euros: 3800
    },
    {
      year: '2009',
      euros: 4800
    },
    {
      year: '2010',
      euros: 5000
    },
    {
      year: '2011',
      euros: 10300
    },
    {
      year: '2012',
      euros: 10300
    },
    {
      year: '2013',
      euros: 9000
    },
    {
      year: '2014',
      euros: 10000
    },
    {
      year: '2015',
      euros: 10500
    },
    {
      year: '2016',
      euros: 10700
    },
    {
      year: '2017',
      euros: 12500
    },
    {
      year: '2018',
      euros: 14000
    },
    {
      year: '2019',
      euros: 17500
    },
    {
      year: '2020',
      euros: 17500
    },
    {
      year: '2021',
      euros: 23500
    }
  ];

  return (
    <Fade in timeout={1000}>
      <div className={classes.statistics}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <PieChartGraph border_top border_right border_bottom />
          </Grid>
          <Grid item xs={12} md={6}>
            <ColumnChartGraph data={data_1} border_top border_bottom>
              <>Entwicklung des Eigenkapitals</>
              <>(T. Euro)</>
            </ColumnChartGraph>
          </Grid>
          <Grid item xs={12} md={6}>
            <ColumnChartGraph data={data_1} border_right border_bottom>
              <>Entwicklung des Eigenkapitals</>
              <>(T. Euro)</>
            </ColumnChartGraph>
          </Grid>
          <Grid item xs={12} md={6}>
            <ColumnChartGraph data={data_1} border_bottom>
              <>Dividendenausschüttungen</>
              <></>
            </ColumnChartGraph>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};
