import { makeStyles } from '@mui/styles'; 

export const appStyles = makeStyles({
  '@global': {
    body: {
      margin: '0',
      padding: '0 !important',
      backgroundColor: '#fff !important'
    }
  },
  app: {
    height: '100vh',
    paddingTop: '6rem'
  },
});
