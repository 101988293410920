import { connect } from 'react-redux';
import { news_setActiveArticle } from '../../../redux/reducers/news/news';

import { useScrollTrigger, useTheme, useMediaQuery } from '@mui/material';

import clsx from 'clsx';

import { SectionBlock } from '../../../components/section-block/sectionBlock';

import { articleBlocksStyles } from './articleBlocks.styles';

const ArticleBlocks = ({ reduxProps, reduxActions }) => {

  const { newsArticles, activeArticleId } = reduxProps;
  const { news_setActiveArticle } = reduxActions;

  const scrollDirection = useScrollTrigger();

  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const classes = articleBlocksStyles({ scrollDirection });

  const handleScrollToTop = () => {
    setTimeout(() => {
      // Scroll back to top of the page
      window.scrollTo(0, 49);
    }, 100);
  }

  const displayArticle = ({ id, quote, image, body }) => {

    news_setActiveArticle({
      id,
      quote,
      image,
      body
    });
  };

  const handleArticleBlockClick = (articleData) => {

    // if (!mobileViewport) {
    //   handleScrollToTop();
    // }
    
    displayArticle(articleData);
    
  }

  return (
    <div className={classes.article_blocks}>
      {newsArticles.map(({ title, sub_title, id, ...restOfProps }, index) => (
        <SectionBlock
          key={`section_block-${index}`}
          news_block
          border_bottom={!mobileViewport}
          onClick={() => handleArticleBlockClick({ id, ...restOfProps })}
          className={clsx(activeArticleId === id ? classes._active : null)}
        >
          <>{title}</>
          <>{sub_title}</>
        </SectionBlock>
      ))}
    </div>
  );
}

const mapStateToProps = (currentState) => ({
  reduxProps: {
    newsArticles: currentState.newsReducer.news.articles,
    activeArticleId: currentState.newsReducer.news.activeArticle.id
  }
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    news_setActiveArticle: (articleData) => dispatch(news_setActiveArticle(articleData))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleBlocks);
