import { Grid } from '@mui/material';

import { useScrollTrigger } from '@mui/material';
import { stickyScrollerBoxStyles } from './StickyScrollerBox.styles';


/*
  PROPS THIS COMPONENT CAN RECEIVE (all booleans):
    - children;
    - border_top, border_bottom;
    - padding (affects only the right column);
*/ 

export const StickyScrollerBox = ({ children, ...restOfProps }) => {
  /*
    Returns a boolean:
    - false = up;
    - true = down;
  */
  const scrollDirection = useScrollTrigger();

  const classes = stickyScrollerBoxStyles({ scrollDirection, ...restOfProps });

  return (
    <Grid container spacing={0} className={classes.sticky_scroller_box}>
      <Grid item xs={12} md={6} className={classes.sticky_scroller_box_left_column}>
        {children[0]}
      </Grid>
      <Grid item xs={12} md={6} className={classes.sticky_scroller_box_right_column}>
        {children[1]}
      </Grid>
    </Grid>
  );
};