// MATERIAL UI
import { useTheme, useMediaQuery, Fade, Grid } from '@mui/material';
// COMPONENTS
import { SectionHeader } from '../../components/section-header/sectionHeader';
import { PdfRow } from '../../components/pdf-row/pdfRow';
import { PdfBlock } from '../../components/pdf-block/pdfBlock';
// STYLES
import { anualGeneralMeetingStyles } from './anualGeneralMeeting.styles';

/*
  OBS: The classes on the contents of 'StickyScrollerBox' have no 
  styles. They serve only for making debugging easy on devtools.
  Their actual styles are in the 'StickyScrollerBox' component.
*/

export const AnualGeneralMeeting = () => {
  // STYLE HOOKS
  const classes = anualGeneralMeetingStyles();
  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Fade in timeout={1000}>
      <div className={classes.anual_general_meeting}>
        <Grid container>
          <PdfBlock border_top border_right border_bottom>
            <>Ordentliche Hauptversammlung</>
            <>2022 Ergebnisse</>
          </PdfBlock>
          <PdfBlock border_top={!mobileViewport} border_bottom>
            <>Bericht des Aufsichtsrats</>
            <></>
          </PdfBlock>
        </Grid>
        <Grid container className={classes.anual_general_meeting_row}>
          <SectionHeader>Publiziert in 2022</SectionHeader>
          <PdfRow>
            <>Einladung zur Hauptversammlung</>
            <></>
          </PdfRow>
        </Grid>
        <Grid container className={classes.anual_general_meeting_row}>
          <SectionHeader>2021</SectionHeader>
          <PdfRow>
            <>Ordentliche Hauptversammlung - Ergebnisse</>
            <></>
          </PdfRow>
          <PdfRow>
            <>Bericht des Aufsichtsrats</>
            <></>
          </PdfRow>
          <PdfRow>
            <>Einladung zur Hauptversammlung</>
            <></>
          </PdfRow>
        </Grid>
        <Grid container className={classes.anual_general_meeting_row}>
          <SectionHeader>2020</SectionHeader>
          <PdfRow>
            <>Ordentliche Hauptversammlung - Ergebnisse</>
            <></>
          </PdfRow>
          <PdfRow>
            <>Bericht des Aufsichtsrats</>
            <></>
          </PdfRow>
          <PdfRow>
            <>Einladung zur Hauptversammlung</>
            <></>
          </PdfRow>
        </Grid>
      </div>
    </Fade>
  );
};
