import { makeStyles } from '@mui/styles'; 

export const sectionBlockStyles = makeStyles((theme) => ({
  section_block: {
    width: '100%',
    height: '100vw',

    padding: '1.5rem',
    // paddingBottom: '5.5rem',

    position: 'relative',

    cursor: 'pointer',

    transition:
      'min-height .5s ease-in-out, background .2s ease-in-out, opacity .2s ease-in-out',

    borderTop: (props) =>
      props.border_top ? `1px solid ${theme.palette.primary.light_gray}` : null,
    borderRight: (props) =>
      props.border_right
        ? `1px solid ${theme.palette.primary.light_gray}`
        : null,
    borderBottom: (props) =>
      props.border_bottom
        ? `1px solid ${theme.palette.primary.light_gray}`
        : null,

    '&:hover': {
      '& h2, p': {
        opacity: (props) => (!props.black_background ? '.42' : '.66')
      }
    },
    // [theme.breakpoints.up('sm')]: {
    //   minHeight: (props) => (props.news_block ? '21vw' : '50vw')
    // },
    [theme.breakpoints.up('md')]: {
      height: '25rem',
    },
    [theme.breakpoints.up('xl')]: {
      height: '28.125rem'
    }
    // [theme.breakpoints.up('xxl')]: {
    //   minHeight: (props) => (props.news_block ? '21vh' : '31vh')
    // }
  },
  section_block__arrow: {
    width: '8rem !important',
    height: '8rem !important',

    fill: `${theme.palette.primary.black} !important`,

    position: 'absolute',
    top: '-1.5rem',
    right: '-4rem'
  },
  section_text_wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  section_block_title: {
    transition: 'opacity .2s ease-in-out, color .2s ease-in-out',

    color: (props) =>
      props.date ? theme.palette.primary.pink : theme.palette.primary.main, // lighter shade of black

    /*
      These styles are in case the data from the CMS
      comes as a p tag
    */
    '& p': {
      margin: '0',

      transition: 'inherit',

      color: 'inherit !important'
    }
  },
  section_block_sub_title: {
    transition: 'opacity .2s ease-in-out',

    color: (props) =>
      props.news_block
        ? theme.palette.primary.light_gray
        : theme.palette.primary.main, // lighter shade of black

    /*
      These styles are in case the data from the CMS
      comes as a p tag
    */
    '& p': {
      margin: '0',

      transition: 'inherit',

      color: 'inherit !important'
    }
  }
}));